#ranking-leaderboard__container {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

table a {
  text-decoration: none;
  font-weight: bold;
}

#ranking-leaderboard {
  width: calc(var(--ranking-table-width) - var(--sidebar-width));
  min-width: var(--ranking-table-min-width);
  height: min-content;
}
#ranking-leaderboard th p {
  color: var(--typography-2);
  font-size: calc(6px + 0.9vw);
}
#ranking-leaderboard td p {
  font-size: calc(5px + 0.8vw);
}

#ranking-leaderboard th {
  border-bottom: 1px solid var(--greyscale-4);
}

#ranking-leaderboard th > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

#ranking-leaderboard__header {
  width: 100%;
  height: 4vw;
  margin-bottom: 0.5vw;
}

.ranking-leaderboard__header-option {
  position: relative;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
#ranking-leaderboard__header-option--twitter > div {
  justify-content: flex-start !important;
}
.ranking-leaderboard__row--twitter {
  text-align: left;
}
.ranking-leaderboard__row--rank {
  color: #888 !important;
}

.table--sorting {
  margin-left: 10px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;  
}
.table--sorting.table--ascending {
  border-bottom: 10px solid var(--typography-2);
}
.table--sorting.table--descending {
  border-top: 10px solid var(--typography-4);
}


.ranking-leaderboard__row {
  width: 100%;
  height: 3vw;
}
.ranking-leaderboard__row--team {
  height: 100%;
  position: relative;
}
.ranking-leaderboard__row--team > img {
  position: absolute;
  height: 70%;
  width: auto;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ranking-leaderboard__row--team > p {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ranking-leaderboard--rank {
  width: 15%;
}
.ranking-leaderboard--username {
  width: 30%;
}
.ranking-leaderboard--team {
  width: 25%;
}
.ranking-leaderboard--rating {
  width: 15%;
}
.ranking-leaderboard--record {
  width: 15%;
}

@media (max-width: 1000px) {
  #ranking-leaderboard {
    width: var(--ranking-table-width);
    min-width: unset;
  }

  #ranking-leaderboard__header {
    height: 6vw;
    margin-bottom: 0.75vw;
  }

  #ranking-leaderboard th p {
    font-size: calc(8px + 1.2vw);
  }
  #ranking-leaderboard td p {
    font-size: calc(7px + 1.1vw);
  }

  .ranking-leaderboard__row {
    height: 4vw;
  }
}