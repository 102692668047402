:root {
  --medium-multiple: calc(375 / 300);
  --large-multiple: calc(450 / 300);

  --sidebar-width: 300px;
  --sidebar-width-medium: calc(var(--sidebar-width) * var(--medium-multiple));
  --sidebar-width-large: calc(var(--sidebar-width) * var(--large-multiple));
  --sidebar-logo-section-width: 25%;

  --sidebar-icon-size: 50px;
  --sidebar-icon-size-medium: calc(var(--sidebar-icon-size) * var(--medium-multiple));
  --sidebar-icon-size-large: calc(var(--sidebar-icon-size) * var(--large-multiple));

  --sidebar-position--model: 127px;
  --sidebar-position--model-medium: 158.8px;
  --sidebar-position--model-large: 191px;

  --sidebar-position--benchmark: 202px;  
  --sidebar-position--benchmark-medium: 252.5px;  
  --sidebar-position--benchmark-large: 303px;  

  --sidebar-position--ranking: 276.5px;  
  --sidebar-position--ranking-medium: 345px;  
  --sidebar-position--ranking-large: 414px;  

  --sidebar-label-font-size: 20px;
  --sidebar-label-font-size-medium: calc(var(--sidebar-label-font-size) * var(--medium-multiple));
  --sidebar-label-font-size-large: calc(var(--sidebar-label-font-size) * var(--large-multiple));

  --sidebar-mask: linear-gradient(90deg, rgba(255,255,255,0.5) 15%, transparent 100%);
  --sidebar-mask--hidden: linear-gradient(90deg, rgba(255,255,255,1) 75%, transparent 100%);
}

/* Styling for sidebar */
#sidebar__background,
#sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 100;
}
#sidebar__background,
#sidebar__background:before {
  background-image: url('../../local-assets/model-page/repeating-hexagons--light.png');
  background-size: 100%;
  -webkit-mask-size: 2000px 2000px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-composite: destination-in;
  mask-size: 2000px 2000px;
  mask-repeat: no-repeat;
  mask-composite: intersect;
  -webkit-mask: var(--sidebar-mask) bottom left;
  mask: var(--sidebar-mask) bottom left;
}

#sidebar__icons,
#sidebar__labels {
  position: absolute;
  top: 0;
}

/* Styling for sidebar icons section */
#sidebar__icons {
  width: var(--sidebar-logo-section-width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  left: 0;
}
#sidebar__logo {
  width: 80%;
  height: auto;
  margin-top: 10px;
  z-index: 100;
}
#sidebar__logo-frost {
  width: calc(var(--sidebar-width) * 0.2);
  height: calc(var(--sidebar-width) * 0.2);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  position: absolute; 
  top: 10px;  
}

.sidebar__icon {  
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: var(--sidebar-icon-size);
  height: var(--sidebar-icon-size);
  border-radius: 50%;
  position: absolute;
  left: 14px;
}
#sidebar__icon--ranking {
  top: var(--sidebar-position--ranking);
}
#sidebar__icon--benchmark {
  top: var(--sidebar-position--benchmark);
}
#sidebar__icon--model {
  top: var(--sidebar-position--model);
}

.sidebar__icon--empty {
  background-image: url('../../local-assets/model-page/hexagon--empty.png');
}
.sidebar__icon--chosen {
  background-image: url('../../local-assets/model-page/hexagon.svg');
  left: 56.5px;
}

/* Styling for sidebar labels section */
#sidebar__labels {
  width: calc(100% - var(--sidebar-logo-section-width));
  height: 100%;
  right: 0;
}
.sidebar__label {
  position: absolute;
  opacity: 0.5;
  transform: translateY(
    calc(
      (var(--sidebar-icon-size) - var(--sidebar-label-font-size)) / 2
    )
  )
}

.sidebar__label--chosen {
  left: 45px;
  opacity: 1;
  color: white;
}

.sidebar__label > a {
  text-decoration: none;
  font-size: var(--sidebar-label-font-size);
}
#sidebar__label--benchmark {
  top: var(--sidebar-position--benchmark)
}
#sidebar__label--ranking {
  top: var(--sidebar-position--ranking)
}
#sidebar__label--model {
  top: var(--sidebar-position--model)
}

/* Sidebar expand */
#sidebar__expand {
  position: fixed;
  left: 8px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("../../local-assets/model-page/aiarena-logo--white.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: none;
  z-index: 1001;
}

/* Responsiveness */
@media (max-width: 1000px) {
  /* Sidebar and Content Container */
  #sidebar__background,
  #sidebar {
    display: none
  }
  #app-content--right-side {
    width: 100%;
  }

  #sidebar__expand {
    display: flex;
  }

  #sidebar__background {
    background-image: var(--background-gradient-simple);
    -webkit-mask: var(--sidebar-mask--hidden) bottom left;
    mask: var(--sidebar-mask--hidden) bottom left;
  }
  #sidebar__background:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url('../../local-assets/model-page/repeating-hexagons--light.png');
    background-size: 100%;
  }
}

@media (min-width: 1600px) {
  #sidebar__background,
  #sidebar {
    width: var(--sidebar-width-medium);
    display: flex;
  } 
  #sidebar__logo-frost {
    width: calc(var(--sidebar-width-medium) * 0.2);
    height: calc(var(--sidebar-width-medium) * 0.2);
  }

  .sidebar__icon {
    width: var(--sidebar-icon-size-medium);
    height: var(--sidebar-icon-size-medium);
    left: 16.5px;
  }

  #sidebar__icon--ranking,
  #sidebar__label--ranking {
    top: var(--sidebar-position--ranking-medium);
  }
  #sidebar__icon--benchmark,
  #sidebar__label--benchmark {
    top: var(--sidebar-position--benchmark-medium);
  }
  #sidebar__icon--model,
  #sidebar__label--model {
    top: var(--sidebar-position--model-medium);
  }

  #sidebar__labels {
    right: -10px;
  }
  .sidebar__label {
    transform: translateY(
      calc(
        (var(--sidebar-icon-size-medium) - var(--sidebar-label-font-size-medium)) / 2
      )
    )
  }
  .sidebar__label > a {
    font-size: var(--sidebar-label-font-size-medium);
  }

  .sidebar__icon--chosen {
    left: 70.5px;
  }
}

@media (min-width: 2000px) {
  #sidebar__background,
  #sidebar {
    width: var(--sidebar-width-large);
  } 
  #sidebar__logo-frost {
    width: calc(var(--sidebar-width-large) * 0.2);
    height: calc(var(--sidebar-width-large) * 0.2);
  }

  .sidebar__icon {
    width: var(--sidebar-icon-size-large);
    height: var(--sidebar-icon-size-large);
    left: 20px;
  }

  #sidebar__icon--ranking,
  #sidebar__label--ranking {
    top: var(--sidebar-position--ranking-large);
  }
  #sidebar__icon--benchmark,
  #sidebar__label--benchmark {
    top: var(--sidebar-position--benchmark-large);
  }
  #sidebar__icon--model,
  #sidebar__label--model {
    top: var(--sidebar-position--model-large);
  }

  #sidebar__labels {
    right: -20px;
  }
  .sidebar__label {
    transform: translateY(
      calc(
        (var(--sidebar-icon-size-large) - var(--sidebar-label-font-size-large)) / 2
      )
    )
  }
  .sidebar__label > a {
    font-size: var(--sidebar-label-font-size-large);
  }

  .sidebar__icon--chosen {
    left: 85px;
  }
}