:root {
  --charts-no-data-font-size: 1.5vw;
}

/* Styling for Model Analytics */
#model-page__charts {
  position: relative;
  min-height: min-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 85%; 
  height: calc((100vw - var(--sidebar-width)) * 0.32 + 3vw);
  padding: 0 5vw;
}

#model-page__charts--stacked-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 54%;
  height: calc((100vw - var(--sidebar-width)) * 0.32);
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
}
#model-page__charts--chord-diagram {
  position: relative;
  width: 44%;
  height: calc((100vw - var(--sidebar-width)) * 0.32);
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
}

#model-page__charts--stacked-bar > p:not(.model-page__charts--no-data),
#model-page__charts--chord-diagram > p:not(.model-page__charts--no-data) {
  position: absolute;
  top: 1vw;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5vw;
}

.model-page__charts--no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--greyscale-3);
  white-space: nowrap;
  font-size: var(--charts-no-data-font-size);
}

#model-page__no-data-text {
  position: absolute;
  top: 50px;
  color: var(--typography-2);
  opacity: 0.3;
}

/* Chord Diagram */
.chord-diagram-group {
  transform: translate(40%, 50%);
}

.chord-diagram-group path {
  cursor: pointer;
}


@media (max-width: 1000px) {
  #model-page__charts {
    height: 35vw;
  }
  #model-page__charts--stacked-bar,
  #model-page__charts--chord-diagram {
    height: 32vw;
  }


  #model-page__charts--stacked-bar > p:not(.model-page__charts--no-data),
  #model-page__charts--chord-diagram > p:not(.model-page__charts--no-data) {
    top: 1.3vw;
    font-size: 2vw;
  }
  .model-page__charts--no-data {
    font-size: 2vw;
  }
}