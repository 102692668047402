#wallet-connect__container {
  background-color: transparent;
  color: white;
  width: min-content;
  float: right;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  pointer-events: none;
  margin: 10px 0 20px 0;
}
#wallet-connect__container button {
  pointer-events: auto;
}

#wallet-connect__button--connect,
#wallet-connect__button--disconnect {
  background-color: rgba(26, 28, 43, 0.5);
  border: none;
  height: min-content;
  border-radius: 5px;  
  width: min-content;
  padding: var(--wallet-height-padding) 20px;  
  cursor: pointer;
  color: white;
  margin-right: 80px;
  font-size: var(--wallet-font-size);  
}
#wallet-display__address {
  color: white;
  font-size: 1.1em;
}
#wallet-connect__button--connect:hover {
  background-color: rgba(26, 28, 43, 0.75);  
  color: var(--typography-2);
}
#wallet-connect__button--disconnect:hover {
  background-color: rgba(26, 28, 43, 0.65);  
  color: var(--typography-2);
}

.show{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.show button {
  width: 100%;
  margin-top: 1rem;
  cursor: pointer;
}

.hide{
  display: none;
}

.pulsing-connect-button {
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    background-color: #d45a6a;
  }
  100% {
    transform: scale(.9);
    background-color: rgba(26, 28, 43, 0.5);
  }
}

@media (min-width: 1600px) {
  #wallet-connect__button--connect,
  #wallet-connect__button--disconnect {
    padding: var(--wallet-height-padding-medium) 20px;
    font-size: var(--wallet-font-size-medium);  
  }

  #wallet-display__address {
    font-size: calc(1.1em * var(--medium-multiple));
  }
}

@media (min-width: 2000px) {
  #wallet-connect__button--connect,
  #wallet-connect__button--disconnect {
    padding: var(--wallet-height-padding-large) 20px;
    font-size: var(--wallet-font-size-large);  
  }

  #wallet-display__address {
    font-size: calc(1.1em * var(--large-multiple));
  }  
}