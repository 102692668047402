:root {
  --highlevel-height: 12vw;
  --new-highlevel-height: 17vw;
  --no-sidebar-multiple--highlevel: calc(17 / 12);
  --highlevel-buffer: 3vw;
  --highlevel-guest-text-size: 1.4vw;
}

#model-page__high-level {
  position: relative;
  width: 85%;
  height: var(--highlevel-height);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 5vw;
  margin-top: var(--highlevel-buffer);
}

@media (max-width: 1000px) {
  #model-page__high-level {
    height: var(--new-highlevel-height);
    margin-top: calc(var(--highlevel-buffer) * var(--no-sidebar-multiple--highlevel));
  }
}