/* Home Intro Visual */
.home-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-screen > h1 {
  margin: 0;
  font-size: 5vw;
}

/* Temporary styling */
#landing-page__container {
  width: 100%;
  height: 100%;
  background-image: url('../../local-assets/landing-page/globe-background-glow.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

#landing-page-visual {
  width: 100%;
  height: 100%;
}

#enter-platform-password,
#home__access-button,
#home__enter-button {
  position: absolute;
  left: 10px;
}
#enter-platform-password {
  top: 10px;
  background-color: var(--greyscale-1);
  border: 1px solid var(--greyscale-1);
  width: 130px;
  padding: 0 10px;
  height: 30px;
  color: white;
  border-radius: 5px;
}
#home__access-button {
  top: 50px;
}
#home__enter-button {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
#home__access-button,
#home__enter-button {
  width: 150px;
  height: 30px;
  border: 1px solid var(--typography-3);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#home__access-button:hover,
#home__enter-button:hover {
  background-color: var(--typography-3);
  color: white;
}

#hello {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
}


#landing-page__container > #wallet__container {
  align-items: flex-end;
}
#landing-page__container > #wallet__container button,
#landing-page__container #wallet-display__address {
  margin: 0;
  margin-right: 20px;
}
#landing-page__container #network-selection-dropdown__selection {
  display: none;
}

/* Test for landing page */
#hyperbolic-time-chamber {
  /* width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../local-assets/stages/hyperbolic_chamber_LAYER_1.png');
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat; */
}