#network-selection__container {
  height: min-content;
  margin-top: 10px;
  margin-right: 0;
}
#network-selection-dropdown__selection {
  border: none;
  width: min-content;
  height: min-content;
  cursor: pointer;
}
#network-selection-dropdown__selection p {
  display: none;
}
#network-selection-dropdown__selection:hover {
  background-color: transparent;
}
#network-selection-dropdown__selection:hover img { 
  filter: brightness(0.65);
}

#network-selection-dropdown__selection img {
  height: calc(var(--wallet-font-size) * 2.5);
}

@media (min-width: 1600px) {
  #network-selection-dropdown__selection img {
    height: calc(var(--wallet-font-size-medium) * 2.5);
  }
}

@media (min-width: 2000px) {
  #network-selection-dropdown__selection img {
    height: calc(var(--wallet-font-size-large) * 2.5);
  }
}