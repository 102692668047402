.fighter-health {
  position: absolute;
  width: 30%;
  height: calc(30% * 1.75 * 0.2857);
  top: 40px;
}

.health-bar__container {
  position: absolute;
  height: 27%;
  width: 90%;
  top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.health-bar {
  position: relative;
  width: 71.5%;
  height: 80%;
  display: flex;
  flex-direction: row;
}
.health-bar > img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.fighter-icon__container {
  position: absolute;
  overflow: visible;
  height: 100%;
  width: 28.57%;
  display: flex;
  justify-content: center;
}

.fighter-icon--top {
  position: absolute;
  left: 0;
  top: 0.8px;
  width: 100%;
  height: auto;
}
.fighter-icon--bottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.fighter-icon {
  position: relative;
  overflow: hidden;
  width: 95%;
  height: 95%;
  border-radius: 50%;
}
.fighter-type {
  position: absolute;
  width: calc(var(--card-width) * 0.12);
  height: calc(var(--card-width) * 0.12);
}