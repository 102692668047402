#interactive-scroll-visual {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

#interactive-scroll-contents {
  width: 100vw;
  height: 800vw;
  z-index: 99;
  position: absolute;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  font-size: 5vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
#interactive-scroll-contents::-webkit-scrollbar {
  display: none;
}

/* Contents */

/* Shared Styling */
#landing-page__slogan  {
  position: fixed;
  display: none;
  flex-direction: column;
  align-items: flex-start ;
}

/* Slogan */
#landing-page__slogan {
  position: absolute;
  display: flex;
  left: 30px;
  top: 3vh;
}
#landing-page__slogan > h1 {
  font-size: 5vh;
  color: white;
  margin-bottom: 1vh;
}
#landing-page__slogan > p {
  font-size: 3vh;
  color: var(--greyscale-4);
  margin-bottom: 0.5vh;
}
