.modal-close-button__container {
  height: 46px;
  width: 46px;
  border-radius: 10px solid red;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 15px;
}
.modal-close-button {
  color: var(--typography-4);
  width: 5rem;
  height: 2rem;
  pointer-events: auto;
}
.modal-close-button:hover {
  color: var(--typography-5);
}