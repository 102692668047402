.slider__parent {
  width: 300px;
  text-align: left;
  margin: 10px 20px;
}

.middle {
  padding-top: 7px;
  width: 100%;
}
.slider__container {
  margin-top: 7px;
  position: relative;
}
.slider__container .bar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 5px;
  width: 99%;
  height: 4px;
  border-radius: 5px;
  background-color: #ddd;
  overflow: hidden;
}
.slider__container .bar .fill {
  display: block;
  width: 0%;
  height: 100%;
  background-color: var(--typography-4);
}
.slider__container .slider {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  width: 100%;
  bottom: 4px;
  right: 2px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
}
.slider__container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: var(--typography-4);
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 0 0 rgba(255, 119, 111, 0.1);
  transition: .3s ease-in-out;
}

.slider__container .slider::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 7px rgba(255, 119, 111, 0.2);
}
.slider__container .slider:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(255, 119, 111, 0.3);
}

label {
  color: #ccc;
}
label > span {
  color: white;
}
