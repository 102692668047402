:root {
  --picture-container-size: 7vw;
  --profile-text-size: 1.3vw;
  --profile-text-margin-top: 0.75vw;
  --profile-guest-text-size: 1.5vw;
}

#model-profile__container {
  width: 18%;
  height: var(--highlevel-height);
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#model-profile__picture-container {
  width: var(--picture-container-size);
  height: var(--picture-container-size);
  border-radius: 50%;
  border: 2px solid var(--greyscale-2);
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}
#model-profile__container > p {
  font-size: var(--profile-text-size);
  margin-top: var(--profile-text-margin-top);
  color: var(--greyscale-4);
}

#model-profile__no-data {
  color: vaR(--greyscale-3) !important;
}

/* Guest Model Styling */
#model-profile__guest-title {
  color: var(--typography-4) !important;
  font-size: var(--profile-guest-text-size) !important;
}
#model-profile__guest-content {
  width: 80%;
  font-size: calc(var(--profile-guest-text-size) * 0.7) !important;
}

#model-profile__remove-model {
  width: min-content;
  height: min-content;
  padding: 0.5vw 1vw;  
  border-radius: 0.2vw;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: var(--profile-text-margin-top);
}
#model-profile__remove-model > p {
  font-size: calc(var(--profile-guest-text-size) * 0.7) !important; 
}
#model-profile__remove-model:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Responsiveness */
@media (max-width: 1000px) {
  #model-profile__container {
    height: calc(var(--highlevel-height) * var(--no-sidebar-multiple--highlevel));
  }
  #model-profile__picture-container {
    width: calc(var(--picture-container-size) * var(--no-sidebar-multiple--highlevel));
    height: calc(var(--picture-container-size) * var(--no-sidebar-multiple--highlevel)); 
  }
  #model-profile__container > p {
    font-size: calc(var(--profile-text-size) * var(--no-sidebar-multiple--highlevel));
    margin-top: calc(var(--profile-text-margin-top) * var(--no-sidebar-multiple--highlevel));
  }

  #model-profile__guest-title {
    font-size: calc(var(--profile-guest-text-size) * var(--no-sidebar-multiple--highlevel)) !important;
  }
  #model-profile__guest-content,
  #model-profile__remove-model > p {
    font-size: calc(var(--profile-guest-text-size) * var(--no-sidebar-multiple--highlevel) * 0.7) !important;
  }
  #model-profile__remove-model {
    padding: calc(0.5vw * var(--no-sidebar-multiple--highlevel)) calc(1vw * var(--no-sidebar-multiple--highlevel));
  }
}