:root {
  --ranked-container-height: 25vw;
  --new-ranked-container-height: 32vw;
  --no-sidebar-multiple--ranked: calc(32 / 25);

  --ranked-container-padding-top: 4vw;
  --ranked-container-margin-bottom: 1.5vw;

  --ranked-container-text-top: 1.5vw;
  --ranked-container-font-size: 2vw;
}

#ranked-summary__container {
  position: relative;
  width: 85%;
  height: var(--ranked-container-height);
  border-radius: var(--container-radius);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--ranked-container-margin-bottom);
}

@media (max-width: 1000px) {
  #ranked-summary__container {
    height: var(--new-ranked-container-height);
    margin-bottom: calc(var(--ranked-container-margin-bottom) * var(--no-sidebar-multiple--ranked));
  }
}