/* Styling for Battle Arena */
.gameplay-screen__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#training-screen__container::before,
#simulation-screen__container--normal::before,
#simulation-screen__container--red::before,
#battle-screen__container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: 700px;
}
#training-screen__container::before {
  background-image: url("../../local-assets/repeatable-backgrounds/sky.webp");
  opacity: 0.5
}
#battle-screen__container::before {
  background-image: url("../../local-assets/repeatable-backgrounds/techy-wallpaper.webp");
  opacity: 0.3
}
/* #simulation-screen__container--normal::before {
  background-image: url("../../local-assets/repeatable-backgrounds/light-wood.webp");
  opacity: 0.7
} */
/* #simulation-screen__container--red::before {
  background-image: url("../../local-assets/repeatable-backgrounds/red.webp");
  opacity: 0.5
} */

#battle-arena {
  position: relative;
  border: 5px solid var(--typography-3);
  border-radius: 10px;
  overflow: hidden;
}
.battle-stage {
  background-image: url("../../local-assets/stages/ranked-battle-background.webp");
  background-position: 35% 0%;
  background-repeat: no-repeat;
  background-size: cover;
}
.champion-stage {
  background-image: url("../../local-assets/stages/throne-background.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.training-stage {
  background-image: url("../../local-assets/stages/training-stage.webp"),
                    url("../../local-assets/stages/training-clouds.webp"),
                    url("../../local-assets/stages/training-moon.webp"),
                    linear-gradient(rgb(37, 107, 213), rgb(186, 215, 251));
  background-position: bottom 11% center, top, top, top;
  background-repeat: no-repeat;
  background-size: contain, 100% 100%, contain, 100% 100%;
}
.simulation-stage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.simulation-stage--normal {
  background-image: url("../../local-assets/stages/simulation-dojo.webp");
}
.simulation-stage--red {
  background-image: url("../../local-assets/stages/simulation-dojo-red.webp");
}

#battle-title,
#battle-subtitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}
#battle-title {
  top: 28%;
  font-size: 2.5em;
}
#battle-subtitle {
  top: 41%;
  white-space: nowrap;
}

#battle-loading__container {
  position: absolute;
  left: 35%;
  color: white;
  display: none;
}


.battle-container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.battle-container h3 {
  position: absolute;
  top: 30%;
  font-size: 3em;
}

.clock {
  position: absolute;
  top: 0%;
  width: 12%;
  height: calc(12% * 1.75 * 1.3);
  background-image: url("../../local-assets/game-play/battle-clock.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clock h2 {
  transform: translateY(10%);
  font-size: 4rem;
  color: var(--greyscale-2);
}

.gameplay-menu--horizontal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--typography-3);
  border-radius: 5px;
}
.gameplay-menu--horizontal {
  height: 10%;
  min-width: 100px;
  flex-direction: row;
  top: 86%;
  z-index: 100;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 250% 250%;
}
#gameplay-menu--battle {
  background-image: url("../../local-assets/game-play/battle-stage-old.webp");
  background-size: 900% 900%;
  background-position: 50% 100%;
}
#gameplay-menu--training {
  background-image: url("../../local-assets/stages/training-clouds.webp"),
                    linear-gradient(rgb(186, 215, 251), rgb(186, 215, 251));
}

.icon-text__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
}
.icon-text__container > img {
  height: 50% !important;
}
.icon-text__container > h2 {
  margin-top: 5px;
  color: var(--greyscale-2);
  cursor: default;
}
.gameplay-menu--horizontal > .icon-text__container {
  margin: 5px 10px;
}

.view-ai__button {
  position: relative;
  width: 30px;
  height: 30px;
  border: 3px solid var(--greyscale-2);
  border-radius: 50%;
  cursor: pointer;
}
.view-ai__button > img {
  width: 100%;
  height: 100%;
}
.hide-ai__button {
  display: none;
  width: 100%;
  height: 3px;
  background-color: red;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.exit-button {
  width: 30px;
  height: auto;
  cursor: pointer;
}
.exit-button:hover {
  filter: contrast(0);
}


/* Styling for Animation Before the Fight */
#ready-fight-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 800px;
  height: 500px;
  z-index: 100;
  pointer-events: none;
  overflow: visible;
}

/* Styling for the Health Bar Display */
/* .fighter-health {
  position: absolute;
  width: 30%;
  height: calc(30% * 1.75 * 0.2857);
  top: 40px;
}

.health-bar__container {
  position: absolute;
  height: 27%;
  width: 90%;
  top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.health-bar {
  position: relative;
  width: 71.5%;
  height: 80%;
  display: flex;
  flex-direction: row;
}
.health-bar > img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.fighter-icon__container {
  position: absolute;
  overflow: visible;
  height: 100%;
  width: 28.57%;
  display: flex;
  justify-content: center;
}

.fighter-icon--top {
  position: absolute;
  left: 0;
  top: 0.8px;
  width: 100%;
  height: auto;
}
.fighter-icon--bottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.fighter-icon {
  position: relative;
  overflow: hidden;
  width: 95%;
  height: 95%;
  border-radius: 50%;
}
.fighter-type {
  position: absolute;
  width: calc(var(--card-width) * 0.12);
  height: calc(var(--card-width) * 0.12);
} */

/* Styling for User/Fighter Info */
.fighter-owner__container {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 10px;
}
.fighter-owner__container > p {
  position: relative;
  color: var(--greyscale-4);
  font-size: 1em;
}
.fighter-owner__container > h3 {
  position: relative;
  font-size: 1.4em;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#fighter-owner--left {
  left: 30px;
  justify-content: flex-start;
}
#fighter-owner--right {
  right: 30px;
  justify-content: flex-end;
}

/* Styling for Best of Three */
.best-of-three__container {
  position: absolute;
  top: calc(5.2% + 40px);
  width: 4%;
  height: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
#best_of_three__container-0 {
  left: calc(29.5% + 20px);
}
#best_of_three__container-1 {
  right: calc(29.5% + 20px);
}
.best-of-three__result {
  width: 65%;
  height: 25%;
  border-radius: 50%;
  border: 0.1vw solid white;
}
.best-of-three__result > img {
  width: 100%;
  height: 100%;
}

/* Styling for the round announcement */
#which-round-visual {
  display: none;
  position: absolute;
  width: 120%;
  height: 20%;
  background-color: #020734;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0.8;
}
#which-round-visual > h1 {
  font-family: steps-light;
  margin: 0;
  color: var(--typography-4);
}

/*
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */
