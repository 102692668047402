:root {
  --historical-elo-width: 69%;
  --historical-elo-border-radius: 0.75vw;
  --historical-elo-title-font-size: 1.5vw;
  --historical-elo-no-data-font-size: 1.5vw;
}

#historical-elo__container {
  position: relative;
  width: var(--historical-elo-width);
  height: 100%;
  border-radius: var(--historical-elo-border-radius);
  background-color: var(--container-color-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

#historical-elo__container > h3 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--historical-elo-title-font-size);
}
#historical-elo__container > p {
  color: var(--greyscale-3);
  white-space: nowrap;
  font-size: var(--historical-elo-no-data-font-size);
}

/* Responsiveness */
@media (max-width: 1000px) {
  #historical-elo__container > h3 {
    font-size: calc(var(--historical-elo-title-font-size) * var(--no-sidebar-multiple--ranked));
  }
  #historical-elo__container > p {
    font-size: calc(var(--historical-elo-no-data-font-size) * var(--no-sidebar-multiple--ranked))
  }
}