#inspector-screen__container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: 100%;
  background-color: rgb(2, 10, 54)
}
.inspector-arena {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../local-assets/stages/ai-inspector-background.webp");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 125%;
  overflow: visible !important;
}
#inspector-stage {
  position: absolute;
  bottom: 5.5%;
  left: -7.5%;
  width: 117%;
  height: auto;
}

#change-attributes__container,
#other-actions__container {
  position: absolute;
  width: 200px;
  height: 5%;
  min-height: 20px;
  text-align: left;
  z-index: 101;
}
#change-attributes__container {
  bottom: calc(3%);
  left: calc(50%);
  transform: translateX(-50%);
  width: 22%;
}
#other-actions__container {
  top: calc(33% + 20px + 7px);
  left: calc(8% + 20px);
  width: 19%;
}
#change-attributes,
#other-actions {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
#change-attributes:hover,
#other-actions:hover {
  border-color: white;
  background-color: rgba(255,255,255,0.1);
}
#change-attributes > p,
#other-actions > p {
  color: white;
  font-size: 1vw;
  /* font: 400 15px Arial; */
  padding-left: 20px;
  pointer-events: none;
}
#other-actions > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#change-attributes > img,
#other-actions > img {
  width: 15px;
  height: auto;
  transform: rotate(-90deg);
  margin-right: 12px;
  pointer-events: none;
}

#change-attributes__options,
#other-actions__list {
  display: none;
  position: absolute;
  bottom: 100%;
  width: 200px;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  z-index: 10;
  height: min-content;
  padding-top: 10px;
}
#other-actions__list {
  width: min-content;
  top: calc(100% + 2px);
  padding: 5% 10%;
}
#change-attributes__options li,
#other-actions__list li {
  position: relative;
  list-style-type: none;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
}
#change-attributes__options li {
  padding: 5px 0;
  height: 50px;
}
#other-actions__list li {
  height: 10%;
  white-space: nowrap;
}

.probability-label {
  text-decoration: underline;
  margin-bottom: 7px;
  text-align: right;
  transform: translateX(10%);
}
#probability-display {
  position: absolute;
  top: calc(20px + 5%);
  left: calc(20px + 9%);
  width: min-content;
  white-space: nowrap;
}
#probability-delta-display {
  position: absolute;
  top: calc(20px + 5%);
  left: calc(20px + 28%);
  width: 50px;
}
.individual-probability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}
.individual-probability > h3 {
  margin: 0 5px;
}

.inspector-pointer {
  position: absolute;
  z-index: 100;
  pointer-events: none;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inspector-pointer > img {
  width: 10%;
  height: auto;
  margin: 0 15px;
}
#attributes-pointer {
  bottom: 0%;
  left: calc(50% + 90px);
  transform: translateX(-50%);
  z-index: 1000;
}
#your-health-pointer {
  top: calc(20px + 2%);
  left: calc(20px + 21%);
}
#opponent-health-pointer {
  top: calc(20px + 2%);
  right: calc(20px + 21%);
}
#opponent-health-pointer > img {
  transform: scaleX(-1);
}
#your-fighter-pointer {
  bottom: 31%;
  left: 14%;
  flex-direction: row;
}
#your-fighter-pointer > img {
  transform: rotate(-45deg);
}
#opponent-fighter-pointer {
  bottom: 31%;
  right: 14%;
  flex-direction: row-reverse;
}
#opponent-fighter-pointer > img {
  transform: scaleX(-1) rotate(-45deg);
}

#your-fighter-rotate {
  bottom: 45.5%;
  left: 8%;
}
#opponent-fighter-rotate {
  bottom: 45.5%;
  right: 8%;
}

#reset-ai,
#return-to-config {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
#return-to-config {
  display: flex;
  align-items: center;
  justify-content: center;
}
#return-to-config > img {
  /* height: 2vw; */
  width: auto;
  /* margin-right: 1vw; */
}

.save-options {
  bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#save-training,
#no-save-training {
  position: relative;
  transform: translateX(0);
  margin: 0 20px;
  left: 0;
}

#initial-message {
  position: absolute;
  width: 35%;
  max-width: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 1vw;
}
