:root {
  --highlevel-buttons-container-height: 9vw;
  --highlevel-buttons-container-top: 8.2vw;
  --highlevel-button-height: 3.8vw;
}

#model-page__buttons-container {
  position: absolute;
  right: 5vw;
  top: var(--highlevel-buttons-container-top);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 16%;
  height: var(--highlevel-buttons-container-height);
}

.model-button {
  position: relative;
  width: 30%;
  height: var(--highlevel-button-height);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
  cursor: pointer;
}
.model-button > img {
  width: 60%;
  height: auto;
}
.model-button:not(.button-unavailable):hover {
  background-color: rgba(0,0,0,0.4);
}

#benchmark-visual {
  width: 60%;
  height: 60%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#benchmark-visual--bar1,
#benchmark-visual--bar2,
#benchmark-visual--bar3 {
  border: 0.12vw solid white;
  border-radius: 0.15vw;
  width: 25%;
}

#benchmark-visual--bar1 {
  height: 50%;
}
#benchmark-visual--bar2 {
  height: 80%;
}
#benchmark-visual--bar3 {
  height: 20%;
}

.button-unavailable {
  cursor: not-allowed;
  background-color: var(--greyscale-2);  
  /* opacity: 0.5; */
}
.button-unavailable > img,
.button-unavailable > #benchmark-visual > div {
  filter: brightness(0.5);  
}

#model-button--watch:hover #model-button__popup--watch,
#model-button--inspect:hover #model-button__popup--inspect,
#model-button--benchmark:hover #model-button__popup--benchmark {
  display: block
}

/* Popup description */
.model-button__popup {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgb(71 83 110);
  width: 150px;
  height: min-content;
  padding: 10px;
  border-radius: 5px;
  display: none;
  z-index: 1000;
}
.model-button__popup > p {
  font-size: 0.75em;
}

/* Watch model modal */
.modal-watch-benchmark__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-watch-benchmark__option {
  width: 200px;
  height: 40px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
}
.modal-watch-benchmark__option:hover {
  background-color: var(--typography-3);
}
.modal-watch-benchmark__option:hover > p {
  color: white !important;
}

@media (max-width: 1000px) {
  #model-page__buttons-container {
    height: calc(var(--highlevel-buttons-container-height) * var(--no-sidebar-multiple--highlevel));
    top: calc(var(--highlevel-buttons-container-top) * var(--no-sidebar-multiple--highlevel));
  }

  .model-button {
    height: calc(var(--highlevel-button-height) * var(--no-sidebar-multiple--highlevel));
  }
}