:root {
  --highlevel-summary-margin--v: 0.75vw;
  --highlevel-summary-margin--h: 2vw;
  --highlevel-summary-text--big: 1.45vw;
  --highlevel-summary-text--small: 1.2vw;
}

#model-summary__container {
  position: relative;
  width: 35%;
  height: var(--highlevel-height);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
}

.model-summary__details {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 
    var(--highlevel-summary-margin--v) 
    var(--highlevel-summary-margin--h);
}

.model-summary__detail-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.model-summary__text--big {
  font-size:var(--highlevel-summary-text--big);
  color: white;
}
.model-summary__text--small {
  font-size: var(--highlevel-summary-text--small);
  color: var(--greyscale-4);
}

/* Guest Styling */
.model-summary--no-data {
  color: var(--greyscale-3);
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--highlevel-guest-text-size);
  white-space: nowrap;
}

/* Responsiveness */
@media (max-width: 1000px) {
  #model-summary__container {
    height: calc(var(--highlevel-height) * var(--no-sidebar-multiple--highlevel));
  }

  .model-summary__details {
    margin: 
      calc(var(--highlevel-summary-margin--v) * var(--no-sidebar-multiple--highlevel)) 
      calc(var(--highlevel-summary-margin--h) * var(--no-sidebar-multiple--highlevel));
  }

  .model-summary__text--big {
    font-size: calc(var(--highlevel-summary-text--big) * var(--no-sidebar-multiple--highlevel));
  }
  .model-summary__text--small {
    font-size: calc(var(--highlevel-summary-text--small) * var(--no-sidebar-multiple--highlevel));
  }  

  .model-summary--no-data {
    font-size: calc(var(--highlevel-guest-text-size) * var(--no-sidebar-multiple--highlevel));
  }
}