:root {
  --ranked-summary-width: 28%;

  --ranked-summary-title-font-size: 1.6vw;
  --ranked-summary-title-top: 1vw;
  --ranked-summary-border-radius: 0.75vw;
  --ranked-summary-border-margin--v: 1.5vw;

  --ranked-summary-no-data-font-size: 1.5vw;

  --ranked-summary-content-title-margin: 0.3vw;
  --ranked-summary-font-size--title: 1.2vw;
  --ranked-summary-font-size--content: 1.1vw;

  --ranked-summary-result-padding--v: 0.2vw;
  --ranked-summary-result-padding--h: 0.75vw;
  --ranked-summary-result-font-size: 1.3vw;
}

.ranked-summary__aggregate-container {
  width: var(--ranked-summary-width);
  height: 100%;
  border-radius: var(--ranked-summary-border-radius);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  background-color: var(--container-color-1);
  position: relative;
  cursor: pointer;
}

.ranked-summary__aggregate-container:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ranked-summary__container-title {
  position: absolute;
  top: var(--ranked-summary-title-top);
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--ranked-summary-title-font-size);
  white-space: nowrap;
}

.ranked-round__aggregate-container {
  width: 70%;
  height: 100px;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ranked-summary__fighter-container,
.ranked-round__fighter-container {
  width: min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;  
}

.ranked-summary__aggregate-middle,
.ranked-summary__result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ranked-summary__title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--ranked-summary-content-title-margin) 0;
}

.ranked-summary__fighter-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ranked-summary__title {
  color: var(--greyscale-5);
  font-weight: bold;  
  margin-bottom: 3px;
  white-space: nowrap;  
}
.ranked-summary__content {
  color: var(--greyscale-4);
  white-space: nowrap;  
}

.ranked-summary__title {
  font-size: var(--ranked-summary-font-size--title);
}
.ranked-summary__content {
  font-size: var(--ranked-summary-font-size--content);
}

#ranked-summary__logo {
  position: absolute;
  top: 37.5%;
  left: 75%;
  transform: translateX(-50%);
  height: 20%;
  width: auto;
}

.ranked-summary__radar-chart > img {
  height: 100%;
  width: auto;
}

#ranked-summary__no-data {
  color: var(--greyscale-3);
  white-space: nowrap;
  font-size: var(--ranked-summary-no-data-font-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Radar Chart */
.scale {
  fill: transparent;
  stroke: #eee;
}

.caption {
  fill: white;
  text-shadow: none;
  font-size: 15px;
}

/* Line up contents */
.ranked-summary__fighter-container,
.ranked-summary__aggregate-middle {
  height: 35%;
  margin-top: 25%;  
  width: 30%;
}
.ranked-summary__fighter-container {
  justify-content: flex-start;
}
.ranked-summary__aggregate-middle {
  justify-content: space-between;
}

.ranked-summary__radar-chart {
  position: absolute;
  height: 30%;
  top: 60%;  
  left: 50%;
  transform: translateX(-50%);
}
.ranked-summary__radar-chart > svg {
  transform: translate(-15%, -10%);
  width: 140%;
  height: 140%;
}

/* Responsiveness */
@media (max-width: 1000px) {
  .ranked-summary__aggregate-container {
    border-radius: calc(var(--ranked-summary-border-radius) * var(--no-sidebar-multiple--ranked));
  }

  .ranked-summary__container-title {
    font-size: calc(var(--ranked-summary-title-font-size) * var(--no-sidebar-multiple--ranked));
  }

  .ranked-summary__benchmark-title {
    top: calc(var(--ranked-summary-title-top) * var(--no-sidebar-multiple--ranked));
    font-size: calc(var(--ranked-summary-title-font-size) * var(--no-sidebar-multiple--ranked));
  }

  .ranked-summary__title-content {
    margin: calc(var(--ranked-summary-content-title-margin) * var(--no-sidebar-multiple--ranked)) 0;
  }  

  .ranked-summary__title {
    font-size: calc(var(--ranked-summary-font-size--title) * var(--no-sidebar-multiple--ranked));
  }
  .ranked-summary__content {
    font-size: calc(var(--ranked-summary-font-size--content) * var(--no-sidebar-multiple--ranked));
  }

  .ranked-summary__fighter-container .ranked-summary__result-box {
    padding: 
      calc(var(--ranked-summary-result-padding--v) * var(--no-sidebar-multiple--ranked))
      calc(var(--ranked-summary-result-padding--h) * var(--no-sidebar-multiple--ranked));
  }

  .ranked-summary__fighter-container .ranked-summary__result-box p {
    font-size: calc(var(--ranked-summary-result-font-size) * var(--no-sidebar-multiple--ranked));
  }

  #ranked-summary__no-data {
    font-size: calc(var(--ranked-summary-no-data-font-size) * var(--no-sidebar-multiple--ranked));
  }
}