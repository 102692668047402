:root {
  --benchmark-chart-width-base: 80vw;
  --benchmark-chart-min-width: 600px;
  --benchmark-chart-padding-h: 1vw;
}

#benchmark-container {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-top: 3vw;
}