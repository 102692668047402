:root {
  --highlevel-upload-button-height: 7.5vw;
  --highlevel-upload-font-size: 1.6vw;
}

.model-upload__container {
  height: var(--highlevel-upload-button-height);
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--container-radius);
  background-color: var(--typography-5);
  opacity: 0.8;
}

.model-upload__container .spinning-loader {
  width: 50% !important;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;    
}

.dropzone > input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}

.dropzone > h2 {
  color: white;
  font-size: var(--highlevel-upload-font-size);
  font-weight: normal;
  text-transform: uppercase;
  z-index: 1;
  word-spacing: 100px;
}
.dragover {
  opacity: 0.8;
}

#dropzone__neuralnet {
  position: absolute;
  height: 80%;
  width: auto;  
  opacity: 0.2
}
.model-upload__container:hover {
  opacity: 1;
}

#dropzone__add-model {
  color: rgba(117, 182, 117, 0.5);
  font-size: 40px;
}
.dropzone:hover #dropzone__add-model {
  color: rgba(117, 182, 117, 1);
}

/* Modal for action selection */
.action-selection__container,
.attributes__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action-selection__container > h2 {
  margin-bottom: 10px;
}
.action-selection__container > p {
  color: var(--greyscale-4);
  margin-top: 10px;
}

.action-selection__option {
  width: 200px;
  height: 40px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
}

.action-selection__option:hover {
  background-color: var(--typography-3);
}
.action-selection__option:hover > p {
  color: white !important;
}

/* Modal for Attributes Selection */
.attributes__container {
  height: 90%;
  margin-top: 10%;
}
.attributes__container > h2 {
  font-size: 1.2em;
  margin-bottom: 20px;
}
.attributes__container li {
  list-style-type: none;
  width: 80%;
}
.attributes__container .slider__parent {
  height: 25%;
  width: 100%;
  margin: 0;
}
.attributes__container .middle {
  padding: 0;
}

#attributes__remaining-text {
  height: 35px;
  margin-top: 20px;
}
#attributes__upload-button {
  width: 100px;
  height: 35px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#attributes__upload-button:hover {
  background-color: rgba(0,0,0,0.4);
}

/* Switch Network Styling */
#switch-network__container {
  height: var(--highlevel-upload-button-height);
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--container-radius);
  /* border: 1px dashed var(--typography-5); */
  /* cursor: pointer; */
}

#switch-network__container > p {
  color: var(--typography-5);
}

/* #switch-network__container:hover {
  border-color: var(--typography-4);
  background-color: #ff766f2d;
} */

/* Responsiveness */
@media (max-width: 1000px) {
  .model-upload__container,
  #switch-network__container {
    height: calc(var(--highlevel-upload-button-height) * var(--no-sidebar-multiple--highlevel));
  }

  .dropzone > h2 {
    font-size: calc(var(--highlevel-upload-font-size) * var(--no-sidebar-multiple--highlevel));
  }
}

