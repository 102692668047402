:root {
  --rounds-vertical-mask: linear-gradient(0deg, transparent 0%, #fff 5%, #fff 95%, transparent 100%);
}

.match-rounds__container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;  
}

.match-rounds__container > p {
  font-size: 1.5em;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.match-round__fighter-container {
  flex-direction: column;
  position: relative;
  margin-right: 10px;
}

#match-rounds__subcontainer {
  -webkit-mask-size: 2000px 2000px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-composite: destination-in;
  mask-size: 2000px 2000px;
  mask-repeat: no-repeat;
  mask-composite: intersect;
  -webkit-mask: var(--rounds-vertical-mask) bottom left;
  mask: var(--rounds-vertical-mask) bottom left;
  
  margin-top: 8.5%;
  overflow: scroll;
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-rounds__group {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-round__aggregate-container {
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  margin-top: 2.5%;
}

.match-round__round-indicator {
  font-size: 0.7em !important;
}

.match-round__style-health {
  width: 40%;
  height: 85%;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.match-round__action-dominance {
  display: flex;
}

.match-round__title-content {
  margin: 5px 0;
}

.match-round__title {
  color: var(--greyscale-5);
  font-weight: bold;  
  margin-bottom: 3px;
  white-space: nowrap;  
  font-size: 0.9em;
}
.match-round__content {
  color: var(--greyscale-4);
  white-space: nowrap;  
  font-size: 0.7em;
}

.match-round__action-dominance {
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 5px 10px;
  height: 90%;
  width: 150px;
}
.match-round__action-dominance--row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  width: 50px;
  margin: 2px 5px;
}
.match-round__action-dominance--row > img {
  width: 20px;
  height: auto;
  margin-right: 3px;
}
.match-round__action-dominance--row > p {
  font-weight: bold;
}

.match-round__style-health--titles {
  position: relative;
  width: 100%;
  height: 20%;
  text-transform: uppercase;  
}
.match-round__title--style {
  position: absolute;
  left: 27.5%;
}
.match-round__title--health {
  position: absolute;
  left: 55%;
}

.match-round__style-health--row {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.match-round__style-health--row > .match-round__content {
  min-width: 60px;
  text-align: end;
  margin-right: 10px;
}

.match-round__health-row {
  width: 90%;
  height: 15px;
  background-color: var(--cool-1);
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  padding: 2px 3px;
}
.match-round__health-inner {
  height: 80%;
  background: linear-gradient(var(--typography-2), var(--cool-4));
  border-radius: 3px;
}

.match-round__title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.match-round__column_two {
  margin: 0 5px;
}