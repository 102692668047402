:root {
  --benchmark-container-height: 17vw;
  --new-benchmark-container-height: 22vw;
  --no-sidebar-multiple--benchmark-container: calc(22 / 17);

  --benchmark-container-padding-top: 4vw;
  --benchmark-container-margin-bottom: 1.5vw;

  --benchmark-container-text-top: 1.5vw;
  --benchmark-container-font-size: 2vw;
}

#benchmark-summary__container {
  position: relative;
  width: 85%;
  height: var(--benchmark-container-height);
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: var(--benchmark-container-padding-top);
  margin-bottom: var(--benchmark-container-margin-bottom);
}

#benchmark-summary__container > p:not(.model-page__charts--no-data) {
  position: absolute;
  top: var(--benchmark-container-text-top);
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--benchmark-container-font-size);
}

.model-page__matches {
  width: 45%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1000px) {
  #benchmark-summary__container {
    height: var(--new-benchmark-container-height);
    padding-top: calc(var(--benchmark-container-padding-top) * var(--no-sidebar-multiple--benchmark-container));
    margin-bottom: calc(var(--benchmark-container-margin-bottom) * var(--no-sidebar-multiple--benchmark-container));
  }

  #benchmark-summary__container > p:not(.model-page__charts--no-data) {
    top: calc(var(--benchmark-container-text-top) * var(--no-sidebar-multiple--benchmark-container));
    font-size: calc(var(--benchmark-container-font-size) * var(--no-sidebar-multiple--benchmark-container));
  }
}