/* Styling for Userpage */
#model-page__container {
  position: relative;
  width: 100%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#model-page__loading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.4);
  z-index: 100;
}
#model-page__loading-container .spinning-loader__container > h1 {
  font-size: 3vw;
  left: 40%;
  top: calc(50% + 8vw);
  transform: translateY(-50%);
}