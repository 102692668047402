/* Container Styling */
.dropdown__container {
  position: relative;
  width: min-content;
  height: 40px;
  z-index: 101;
  margin-right: 20px;
}

.dropdown__options-container {
  position: absolute;
  left: 0%;
  padding: 0 10%;
  top: 105%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--greyscale-1);
  border-radius: 5px;
}

/* Selection Styling */
.dropdown__selection {
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--typography-2);
}
.dropdown__selection:hover {
  background-color: rgba(105, 227, 242, 0.05);
}

.dropdown__option {
  height: 30px;
}
.dropdown__option:hover {
  background-color: #292c43;
}

.dropdown__selection {
  width: min-content;
}
.dropdown__option {
  width: 100%;
}

.dropdown__selection,
.dropdown__option {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: auto;
}

.dropdown__selection > img,
.dropdown__option > img {
  height: 80%;
  width: auto;
  margin-right: 10px;
}

.dropdown__selection > img,
.dropdown__option > img,
.dropdown__selection > p,
.dropdown__option > p {
  pointer-events: none;
}