/* Container styling */
.connect-popup__background {
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.connect-popup__layover {
  width: 100vw;
  height: 100vh;
  background-color: rgba(107, 114, 128, 0.5);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.modalContainer {
  width: 400px;
  height: 400px;
  border-radius: 12px;
  background-color: rgba(27, 32, 51, 0.95);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  position: relative;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Seperator styling */
.connect-popup__separator {
  width: 75%;
  height: 2px;
  margin: 10px 0;
  background-image: linear-gradient(90deg, transparent, var(--typography-2), transparent);
}

/* Providers Styling */
.connect-popup__providers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70%;
}

.connect-popup__wallet-provider {
  color: var(--typography-2);
  border: var(--typography-2) solid 2px;
  border-radius: 15px;
  width: 75%;
  height: 25%;
  padding: 10px 20px;
  font-size: 1.4em;
  cursor: pointer;
  background-color: rgb(27, 32, 51);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.connect-popup__wallet-provider:hover {
  background-color: rgba(105, 227, 242, 0.1);
}

#WalletConnect{
  border-radius: 50%;
}

#coinbase{
  border-radius: 50%;
}

#connect-popup__learn-more-button {
  background-color: var(--greyscale-2);
  cursor: pointer;
  border: none;
  color: var(--typography-2) !important;
  margin: 1rem 0 0 0 ;
  opacity: .8;
  width: min-content;
  white-space: nowrap;
  padding: 5px 10px;
  text-decoration: none;
}

#connect-popup__learn-more-button:hover {
  background-color: var(--typography-3);
  color: white !important;
}

/* Specific wallet popup styling */
.walletconnect-modal__base {
  max-width: 60vw;
}