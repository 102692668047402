:root {
  --sidebar-width: 300px;
  --app-content-width: calc(100vw - var(--sidebar-width));

  --greyscale-1: #1a1c2b;
  --greyscale-2: #31394e;
  --greyscale-3: #5d6d87;
  --greyscale-4: #94aabf;
  --greyscale-5: #d1e6f9;

  --typography-1: #ffffff;
  --typography-2: #69e3f2;
  --typography-3: #1e95bf;
  --typography-4: #ff776f;
  --typography-5: #ca4949;

  --cool-1:  #1c184a;
  --cool-2:  #042278;
  --cool-3:  #1e4cbc;
  --cool-4:  #3166c2;
  --cool-5:  #33c1d9;
  
  --warm-1: #66112d;
  --warm-2: #8a112d;
  --warm-3: #a7112d;
  --warm-4: #ff8e50;
  --warm-5: #f5c868;

  --container-radius: 10px;
  --container-color-1: rgba(0, 0, 0, 0.2);

  --background-gradient:   radial-gradient(
    ellipse at bottom right, var(--greyscale-2) 0%, transparent 40%
  ),
  radial-gradient(
    150% 300% at top right, var(--greyscale-1) 5%, var(--cool-4) 35%, transparent 70%
  ),
  radial-gradient(
    100% 100% at center left, var(--greyscale-1) 15%, var(--cool-4) 70%, transparent 100%
  );

  --background-gradient-simple: linear-gradient(var(--greyscale-1), #274aa3);

  --background-dark-gradient: linear-gradient(
    var(--greyscale-1), var(--cool-1)
  );
  --background-light-gradient: linear-gradient(
    var(--typography-1), var(--greyscale-5)
  );
}

* {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
*:focus {
    outline: 0 !important;
}

html {
  height: 100%;
  overscroll-behavior: none;
}

iframe {
  pointer-events: none;
} 

body {
  background: var(--background-gradient-simple);
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  text-align: center;
  height: 100%;
  overscroll-behavior: none;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

canvas {
  height: 100vh;
  width: 100vw;
  overflow: visible;
  position: absolute;
}

@font-face {
  font-family: steps-light;
  src: url("./external-fonts/STEPS Light.otf");
}

@font-face {
  font-family: agency-fb-bold;
  src: url("./external-fonts/agencyfb-bold.otf");
}

@font-face {
  font-family: bahnschrift;
  src: url("./external-fonts/BAHNSCHRIFT.otf");
}

@font-face {
  font-family: roboto;
  src: url("./external-fonts/roboto/Roboto-Regular.ttf")
}

h1, h2, h3, p {
  /* font-family: bahnschrift; */
  font-family: roboto;
  color: white;
}


#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#app-content {
  flex: 1 0 auto;
}

#app-content--right-side {
  width: var(--app-content-width);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: scroll;
}

.app-content--full-screen {
  width: 100vw !important;
}

/* Button */
.button {
  width: min-content;
  height: min-content;
  background: var(--greyscale-2);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  color: var(--typography-2);
  cursor: pointer;
  font-family: bahnschrift;
  font-size: 0.8em;
  text-transform: uppercase;
  white-space: nowrap;
}
.button:hover,
.button--active {
  background-color: var(--typography-3);
  color: white;
}