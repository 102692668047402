/* AI Arena Logo Loader */
.loader__container {
  height: 100%;
  width: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
}
.simple-loader {
  width: 350px;
  height: 350px;
  min-width: 200px;
  min-height: 200px;
  overflow: visible;
}
.simple-loader-small {
  width: 200px;
  height: 200px;
  overflow: visible;
}
