/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
}

/* Modal Content/Box */
.modal-content {
  position: relative;
  background-color: var(--palette-1);
  box-shadow: 0px 2px 10px 0px #13151c;
  border-radius: 10px;
  width: 400px;
  height: 412px;
}

.custom-modal-content {
  width: 400px;
  height: 412px;
}

/* The Close Button */
.modal-close {
  color: #aaa;
  float: right;
  font-size: 40px;
  position: absolute;
  top: 5px;
  right: 15px;
  width: min-content;
  height: min-content;
  color: var(--palette-5);
}

.modal-close:hover,
.modal-close:focus {
  color: var(--palette-3);
  text-decoration: none;
  cursor: pointer;
}


/* Battle Modal Styling */
.modal-game-over {
  position: relative;
  height: 100%;
}
.model-game-over__buttons-container {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.model-game-over__button {
  width: 40%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}
.model-game-over__button:hover {
  background-color: rgba(0,0,0,0.5);
}


.reward-button,
.simulation-button {
  width: min-content;
  height: 30px;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  white-space: nowrap;
  padding: 12px 10px;
  border-radius: 5px;
  background-color: #2d57a6;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1),
              2px 2px 6px rgba(0, 0, 0, 0.8);
}
.reward-button:hover {
  background-color: #4b71b8;
}

.simulation-button {
  top: 65% !important;
}

.main-modal-button {
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #e0191f;
  border-radius: 30px;
  bottom: 3%;
  left: 43.2%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
}

.side-modal-button {
  position: absolute;
  width: 45px;
  height: 45px;
  background-color: #e3bf30;
  border-radius: 25px;
  bottom: 3.8%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-modal-button > img,
.side-modal-button > img {
  width: 75%;
}
#replay-button {
  left: 29%;
}
#replay-button > img {
  transform: translateX(5%);
}
#save-button {
  left: 61.4%;
}
#save-button > img {
  width: 60%;
}

.main-modal-button,
.side-modal-button {
  cursor: pointer;
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1),
              2px 2px 6px rgba(0, 0, 0, 0.8);
}

.main-modal-button:hover,
.side-modal-button:hover {
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1),
              2px 2px 6px rgba(0, 0, 0, 0.8),
              inset -2px -2px 10px rgba(255, 255, 255, 0.05),
              inset 2px 2px 10px rgba(0, 0, 0, 0.5);
}

#breeding-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}

#breeding-statement {
  width: 60%;
  position: absolute;
  top: 45%;
}
#breeding-button,
#token-reward-button,
#route-train-button,
#route-battle-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  pointer-events: auto;
  top: 60%;
}
#breeding-button {
  padding: 5px 10px;
}
#breeding-button > img {
  height: 100%;
  margin-right: 10px;
}
#token-reward-button > img {
  width: 53%;
  margin-right: 10px;
}
#breeding-button {
  /* left: 35%; */
  left: 50%;
}
#token-reward-button {
  left: 65%;
}

#token-reward-text {
  position: absolute;
  display: none;
  color: green;
  top: 61%;
  left: 60%;
  font-size: 2em;
}

#breeding-unknown-character {
  width: 150px;
  height: auto;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

#hourglass-icon {
  width: 30%;
  position: absolute;
  top: 39%;
}
#no-breeding-statement {
  width: 60%;
  position: absolute;
  bottom: 18%;
}

#route-train-button,
#route-battle-button {
  width: 50px;
  top: 50%;
  background-color: rgb(232, 228, 132);
  color: #333;
}
#route-train-button:hover,
#route-battle-button:hover {
  background-color: #d9ca79;
}
#route-train-button > img,
#route-battle-button > img {
  top: 50%;
  width: 70%;
  margin-right: 10px;
}

#winning-statement,
#losing-statement {
  width: 80%;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);  
}
#winning-statement > h2,
#losing-statement > h2 {
  font-size: 1.1em !important;
}
#winning-statement > h3,
#losing-statement > h3 {
  margin-top: 20px;
  font-size: 0.9em !important;
}
#winning-statement > h3 > span {
  color: green
}

#concluding-statement {
  width: 80%;
  position: absolute;
  top: 70%;
  color: #eee;
  left: 50%;
  transform: translateX(-50%);
}
#concluding-statement {
  top: 20%;
}
