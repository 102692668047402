:root {
  --ranking-highlevel-button-height: 3.5vw;
  --ranking-highlevel-button-height--large: 5vw;
  --ranking-highlevel-button-aspect: calc(10/3.5);
  --ranking-highlevel-top-team-height: 7vw;
  --ranking-highlevel-top-team-height--large: 10vw;
  --ranking-highlevel-top-team-aspect: calc(30/7);
}

#ranking-highlevel__container {
  width: calc(var(--ranking-table-width) - var(--sidebar-width));
  min-width: var(--ranking-table-min-width);
  height: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
}

#ranking-highlevel__top-team {
  height: var(--ranking-highlevel-top-team-height);
  width: calc(var(--ranking-highlevel-top-team-height) * var(--ranking-highlevel-top-team-aspect));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1vw;
}


.ranking-highlevel__img-text {
  height: 50%;
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ranking-highlevel__img {
  height: 80%;
  width: auto;
  margin-right: 0.75vw;
}
.ranking-highlevel__stat {
  font-size: calc(8px + 1.2vw);
  color: var(--greyscale-4);
}
.ranking-highlevel__name {
  font-size: calc(6px + 1.2vw);
  color: white;
  white-space: nowrap;
  margin-right: 2vw;
}

#ranking-highlevel__buttons {
  height: var(--ranking-highlevel-button-height);
  width: calc(var(--ranking-highlevel-button-height) * var(--ranking-highlevel-button-aspect));
  min-height: 30px;
  min-width: calc(30px * var(--ranking-highlevel-button-aspect));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
#ranking-highlevel__buttons > h3 {
  font-size: calc(5px + 0.8vw);
  white-space: nowrap;
}

#ranking-highlevel__run-battle {
  height: 100%;  
  width: 100%;
  background-color: rgba(49, 57, 78, 0.3);
  border-radius: 0.3vw;
  border: 1px solid var(--typography-3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#ranking-highlevel__run-battle > p {
  font-size: calc(5px + 0.9vw);
}

.ranking-highlevel__battle--available {
  border-color: var(--typography-5) !important;
  background-color: var(--typography-5) !important;
  pointer-events: auto;
}
.ranking-highlevel__battle--available:hover {
  border-color: var(--typography-4) !important;
  background-color: var(--typography-4) !important;
}

.ranking-highlevel__battle--not-available {
  border-color: var(--greyscale-4) !important;
  background-color: var(--greyscale-3) !important;
  pointer-events: none;
}

@media (max-width: 1000px) {
  #ranking-highlevel__container {
    width: var(--ranking-table-width);
  }

  #ranking-highlevel__top-team {
    height: var(--ranking-highlevel-top-team-height--large);
    width: calc(var(--ranking-highlevel-top-team-height--large) * var(--ranking-highlevel-top-team-aspect));
    margin-top: 1.5vw;
  }
  .ranking-highlevel__img {
    margin-right: 1.1vw;
  }
  .ranking-highlevel__stat {
    font-size: calc(10px + 1.4vw);
  }
  .ranking-highlevel__name {
    font-size: calc(8px + 1.4vw);
    margin-right: 3vw;
  }
  

  #ranking-highlevel__buttons {
    height: var(--ranking-highlevel-button-height--large);
    width: calc(var(--ranking-highlevel-button-height--large) * var(--ranking-highlevel-button-aspect));
  }
  #ranking-highlevel__run-battle {
    border-radius: 0.5vw;
  }
  #ranking-highlevel__run-battle > p {
    font-size: calc(6px + 1.2vw);
  }
}