:root {
  --highlevel-version-title-size: 1.35vw;
  --highlevel-version-title-margin: 1.5vw;
  --highlevel-version-marker-width: 2.5vw;
  --highlevel-version-marker-size: 1.2vw;
  --highlevel-version-marker-size--center: 2vw;    
}

#model-version-history__container {
   position: relative;
   width: 25%;
   height: var(--highlevel-height);
   border-radius: var(--container-radius);
   background-color: var(--container-color-1);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
 }
 #model-version-history__container > p:not(#model-version-history--guest) {
   color: white;
   font-size: var(--highlevel-version-title-size);
   white-space: nowrap;
   margin-top: var(--highlevel-version-title-margin);
 }
 
 #model-version-history {
   position: relative;
   margin-top: 3%;
   width: 80%;
   height: 50%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
 }
 
.model-version-history__marker {
  width: var(--highlevel-version-marker-width);
  height: var(--highlevel-version-marker-width);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: none;
}

.model-version-history__marker--center,
.model-version-history__marker--left,
.model-version-history__marker--right {
  display: flex;
}
 
.model-version-history__marker--center {
  font-size: var(--highlevel-version-marker-size--center);
}
.model-version-history__marker--left,
.model-version-history__marker--right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--highlevel-version-marker-size);   
}

.model-version-history__marker--center > p {
color: white;
}
.model-version-history__marker--left > p,
.model-version-history__marker--right > p {
color: var(--greyscale-3);
}

.model-version-history__marker--left {
  left: 15%;
}
.model-version-history__marker--right {
  right: 15%;
}

.model-version-history__button {
  position: absolute;
  height: 20%;
  top: 65%;
  transform: translateY(-50%);
  z-index: 100;
  cursor: pointer;
  pointer-events: auto;
}
.model-version-history__button > img {
  height: 100%;
  width: auto;
}
#model-version-history__button--left {
  left: 8%;
  transform: translateY(-50%) scaleX(-1);
}
#model-version-history__button--right {
  right: 8%;
}

/* Guest Styling */
#model-version-history--guest {
  color: var(--greyscale-3);
  margin: 0;
  font-size: var(--highlevel-guest-text-size);
}

 /* Responsiveness */
 @media (max-width: 1000px) {
  #model-version-history__container {
    height: calc(var(--highlevel-height) * var(--no-sidebar-multiple--highlevel));
  }

  #model-version-history__container > p:not(#model-version-history--guest) {
    font-size: calc(var(--highlevel-version-title-size) * var(--no-sidebar-multiple--highlevel));
    margin-top: calc(var(--highlevel-version-title-margin) * var(--no-sidebar-multiple--highlevel));
  }

  #model-version-history--guest {
    font-size: calc(var(--highlevel-guest-text-size) * var(--no-sidebar-multiple--highlevel));
  }

  .model-version-history__marker {
    width: calc(var(--highlevel-version-marker-width) * var(--no-sidebar-multiple--highlevel));
    height: calc(var(--highlevel-version-marker-width) * var(--no-sidebar-multiple--highlevel));
  }

  .model-version-history__marker--center {
    font-size: calc(var(--highlevel-version-marker-size--center) * var(--no-sidebar-multiple--highlevel));
  }
  .model-version-history__marker--left,
  .model-version-history__marker--right {
    font-size: calc(var(--highlevel-version-marker-size) * var(--no-sidebar-multiple--highlevel)); 
  }
}