:root {
  --match-summary-height: 13vw;
  --new-match-summary-height: 17vw;
  --no-sidebar-multiple--match-summary: calc(17 / 13);

  --match-summary-title-font-size: 1.3vw;
  --match-summary-title-top: 1vw;
  --match-summary-border-radius: 0.75vw;
  --match-summary-border-margin--v: 1.5vw;

  --match-summary-content-title-margin: 0.3vw;
  --match-summary-font-size--big-title: 1.2vw;
  --match-summary-font-size--big-content: 1.1vw;
  --match-summary-font-size--small-title: 1vw;
  --match-summary-font-size--small-content: 0.9vw;

  --match-summary-result-padding--v: 0.2vw;
  --match-summary-result-padding--h: 0.75vw;
  --match-summary-result-font-size: 1.3vw;
}

.match-summary__aggregate-container {
  width: 80%;
  padding: 0 5%;
  height: var(--match-summary-height);
  border-radius: var(--match-summary-border-radius);
  margin: var(--match-summary-border-margin--v) 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: var(--container-color-1);
  position: relative;
  cursor: pointer;
}
.match-summary__aggregate-container:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.match-summary__benchmark-title {
  position: absolute;
  top: var(--match-summary-title-top);
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--match-summary-title-font-size);
}

.match-round__aggregate-container {
  width: 70%;
  height: 100px;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.match-summary__fighter-container,
.match-round__fighter-container {
  width: min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;  
}

.match-summary__aggregate-middle,
.match-summary__result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match-summary__title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--match-summary-content-title-margin) 0;
}

.match-summary__fighter-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match-summary__title--big,
.match-summary__title {
  color: var(--greyscale-5);
  font-weight: bold;  
  margin-bottom: 3px;
  white-space: nowrap;  
}
.match-summary__content--big,
.match-summary__content {
  color: var(--greyscale-4);
  white-space: nowrap;  
}

.match-summary__title--big {
  font-size: var(--match-summary-font-size--big-title);
}
.match-summary__content--big {
  font-size: var(--match-summary-font-size--big-content);
}
.match-summary__title {
  font-size: var(--match-summary-font-size--small-title);
}
.match-summary__content {
  font-size: var(--match-summary-font-size--small-content);
}

.match-summary__fighter-container .match-summary__result-box {
  height: min-content;
  width: min-content;
  padding: 
    var(--match-summary-result-padding--v)
    var(--match-summary-result-padding--h);
}
.match-summary__fighter-container .match-summary__result-box p {
  font-size: var(--match-summary-result-font-size);
}

.match-summary__radar-chart > img {
  height: 100%;
  width: auto;
}


/* Radar Chart */
.scale {
  fill: transparent;
  stroke: #eee;
}

.caption {
  fill: white;
  text-shadow: none;
  font-size: 15px;
}

/* Line up contents */
.match-summary__fighter-container,
.match-summary__aggregate-middle,
.match-summary__radar-chart {
  height: 50%;
  margin-top: 10%;
  justify-content: space-between;
  width: 30%;
}

.match-summary__radar-chart > svg {
  transform: translate(-10%, -10%);
  width: 140%;
  height: 140%;
}

/* Responsiveness */
@media (max-width: 1000px) {
  .match-summary__aggregate-container {
    height: calc(var(--match-summary-height) * var(--no-sidebar-multiple--match-summary));
    border-radius: calc(var(--match-summary-border-radius) * var(--no-sidebar-multiple--match-summary));
    margin: calc(var(--match-summary-border-margin--v) * var(--no-sidebar-multiple--match-summary)) 0;
  }

  .match-summary__benchmark-title {
    top: calc(var(--match-summary-title-top) * var(--no-sidebar-multiple--match-summary));
    font-size: calc(var(--match-summary-title-font-size) * var(--no-sidebar-multiple--match-summary));
  }

  .match-summary__title-content {
    margin: calc(var(--match-summary-content-title-margin) * var(--no-sidebar-multiple--match-summary)) 0;
  }  

  .match-summary__title--big {
    font-size: calc(var(--match-summary-font-size--big-title) * var(--no-sidebar-multiple--match-summary));
  }
  .match-summary__content--big {
    font-size: calc(var(--match-summary-font-size--big-content) * var(--no-sidebar-multiple--match-summary));
  }
  .match-summary__title {
    font-size: calc(var(--match-summary-font-size--small-title) * var(--no-sidebar-multiple--match-summary));
  }
  .match-summary__content {
    font-size: calc(var(--match-summary-font-size--small-content) * var(--no-sidebar-multiple--match-summary));
  }

  .match-summary__fighter-container .match-summary__result-box {
    padding: 
      calc(var(--match-summary-result-padding--v) * var(--no-sidebar-multiple--match-summary))
      calc(var(--match-summary-result-padding--h) * var(--no-sidebar-multiple--match-summary));
  }

  .match-summary__fighter-container .match-summary__result-box p {
    font-size: calc(var(--match-summary-result-font-size) * var(--no-sidebar-multiple--match-summary));
  }
}