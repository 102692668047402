.character {
  position: absolute;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}

.svg-responsive__container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-responsive__content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
