#record-gameplay {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#record-gameplay__red-dot {
  height: 40%;
  width: 40%;
  border-radius: 50%;
  background-color: red;
  z-index: 1;
}
#record-gameplay__red-dot--pulsing {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40%;
  width: 40%;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.25);
  animation-name: pulsingRecord;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
}

@keyframes pulsingRecord {
  0% {
    height: 40%;
    width: 40%;
  }
  50% {
    height: 80%;
    width: 80%;
  }
  0% {
    height: 40%;
    width: 40%;
  }
}
