/* Spinning Token Loader */
.spinning-loader__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.spinning-loader {
  width: 15%;
  height: auto;
}
.spinning-loader__container > h1 {
  font-size: 3vw;
  position: absolute;
  top: calc(50% + 5vw);
  left: 42%;
  width: min-content;
  white-space: nowrap;
}

#battle-loading__status {
  top: calc(50% + 10vw) !important;
  left: 50% !important;
  transform: translateX(-50%) !important; 
}
