/* Error Page */
.error-background {
  background: rgb(24, 26, 35);
  background-size: 100%;
}

#error-page__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#error-page {
  width: 100%;
  height: 100%;
  background-color: rgb(24, 26, 35);
  background-image: url('../../../local-assets/aia-specific/not-found-page.webp');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}
#error-page > h1,
#error-page > h2,
#error-page > h3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
#error-page > h1 {
  top: 10%;
  font-size: 8em;
}
#error-page > h2 {
  top: 25%;
  font-size: 4em;
}
#error-page > h3 {
  top: 30%;
  font-size: 2em;
}

#no-access__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
#no-access__container h2 {
  font-size: 2em;
}

#no-access__container > img {
  width: 200px;
  height: auto;
}
.social-icon {
  font-size: 6em;
  margin: 0 30px;
}
.social-icon path {
  color: white;
}
