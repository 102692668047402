#view-selection {
  width: 83%;
  height: 3.5vw;
  min-height: 25px;
  border-bottom: 1px solid var(--greyscale-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.view-selection__option {
  width: 9vw;
  height: 100%;
  margin-right: 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  font-size: calc(4px + 0.9vw);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.view-selection__option--selected {
  border-bottom-color: var(--typography-2);
}

@media (max-width: 1000px) {
  #view-selection {
    width: calc(var(--benchmark-chart-width-base) + var(--benchmark-chart-padding-h));
    min-width: unset;
    min-height: unset;
    height: 5vw;
  }

  .view-selection__option {
    width: 14vw;
    font-size: calc(5px + 1vw);
  }
}
