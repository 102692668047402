#wallet-display__container {
  width: min-content;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; 
}
#wallet-display__address {
  pointer-events: none;
}


#wallet-display__switch-network {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
#wallet-display__switch-network--arrow {
  transform: translateX(-2px);
  -webkit-animation: pointer 1.5s infinite;
}
#wallet-display__switch-network > p {
  color: var(--typography-4);
}

@keyframes pointer {
  0% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(-8px) scale(1.5);
  }
  100% {
    transform: translateX(-2px);
  }
}