#battle-loader-modal__container--you,
#battle-loader-modal__container--opponent {
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10%;
}

#battle-loader-modal__container--you {
  left: 14%;
}
#battle-loader-modal__container--opponent {
  right: 12.5%;
}

#battle-loader-modal__container--you > h3,
#battle-loader-modal__container--opponent > h3 {
  color: var(--typography-3);
  margin-bottom: 10%;
}

#battle-loader-modal__container--you > p,
#battle-loader-modal__container--opponent > p {
  font-size: 0.8em;
}

/* Watch Battle Button */
#ranking-highlevel__watch-battle,
#battle-loader-modal__warning-proceed {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 120px;
  background-color: rgba(49, 57, 78, 0.3);
  border-radius: 0.3vw;
  border: 1px solid var(--typography-3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#ranking-highlevel__watch-battle:hover,
#battle-loader-modal__warning-proceed:hover {
  background-color: var(--typography-3);
}

/* Warning Popup */
#battle-loader-modal__warning-container,
#battle-loader-modal__sign-container {
  width: 90%;  
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
#battle-loader-modal__warning-container > img {
  height: 50px;
  width: auto;  
}
#battle-loader-modal__sign-container > img {
  height: 70px;
  width: auto;
}

#battle-loader-modal__warning-container {
  height: 60%;
}
#battle-loader-modal__sign-container { 
  height: 80%;
}

#battle-loader-modal__warning-proceed {
  transform: translateX(-50%);
  top: 75%;
}

/* Results */
#battle-loader-modal__result-container {
  width: 25%;
  height: 40%;
  border-radius: 5px;
  border: 1px solid var(--typography-3);
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.battle-loader-modal__container {
  width: 100%;
  height: 100%;
}

.battle-loader-modal__container .spinning-loader__container {
  pointer-events: none;
}

.battle-loader-modal__result > h3 {
  font-size: 1em;  
}
.battle-loader-modal__result > p {
  font-size: 0.7em;
  color: var(--greyscale-4)
}