#leaderboard__container {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

table a {
  text-decoration: none;
  font-weight: bold;
}

#leaderboard {
  width: calc(var(--benchmark-chart-width-base) - var(--sidebar-width));
  min-width: var(--benchmark-chart-min-width);
  height: min-content;
}
#leaderboard th p {
  color: var(--typography-2);
  font-size: calc(5px + 0.9vw);
}
#leaderboard td p {
  font-size: calc(5px + 0.9vw);
}

#leaderboard th {
  border-bottom: 1px solid var(--greyscale-4);
}

#leaderboard th > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

#leaderboard__header {
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
}

.leaderboard__header-option {
  position: relative;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
#leaderboard__header-option--twitter > div {
  justify-content: flex-start !important;
}
.leaderboard__row--twitter {
  text-align: left;
}
.leaderboard__row--rank {
  color: #888 !important;
}

.table--sorting {
  margin-left: 10px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;  
}
.table--sorting.table--ascending {
  border-bottom: 10px solid var(--typography-2);
}
.table--sorting.table--descending {
  border-top: 10px solid var(--typography-4);
}


.leaderboard__row {
  width: 100%;
  height: 3vw;
}

.leaderboard__row span {
  font-size: 0.8em;
  color: var(--greyscale-4);
}

@media (max-width: 1000px) {
  #leaderboard {
    width: var(--benchmark-chart-width-base);
    min-width: unset;
  }

  #leaderboard th p {
    font-size: calc(7px + 1.1vw);
  }
  #leaderboard td p {
    font-size: calc(7px + 1.1vw);
  }

  .leaderboard__row {
    height: 4vw;
  }
}