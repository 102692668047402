.svg-responsive__container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-responsive__content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.x-axis,
.y-axis,
.domain,
.tick > text {
  color: var(--typography-2);
}

#benchmark-chart {
  position: relative;
  overflow: visible;
  width: calc(var(--benchmark-chart-width-base) - var(--sidebar-width));
  min-width: var(--benchmark-chart-min-width);
  height: calc((var(--benchmark-chart-width-base) - var(--sidebar-width)) * 0.5);
  min-height: calc(var(--benchmark-chart-min-width) * 0.5);
  margin: 1.5% 3%;
  margin: 3vw 0;
  border-radius: var(--container-radius);
  background-color: var(--container-color-1);
  padding: 2vw var(--benchmark-chart-padding-h);
}

#benchmark-chart__corner--top-left,
#benchmark-chart__corner--top-right,
#benchmark-chart__corner--bottom-left,
#benchmark-chart__corner--bottom-right {
  position: absolute;
  width: 15vw;
  height: auto;
  pointer-events: none;
}
#benchmark-chart__corner--top-left {
  top: -16%;
  left: 0%;
}
#benchmark-chart__corner--bottom-left {
  bottom: -5%;
  left: 0%;
  transform: scaleY(-1);
}
#benchmark-chart__corner--top-right {
  top: -16%;
  right: -1.5%;
  transform: scaleX(-1);
}
#benchmark-chart__corner--bottom-right {
  bottom: -5%;
  right: -1.5%;
  transform: scale(-1);
}

#benchmark-chart .domain {
  color: var(--greyscale-4);
}

.scatter-plot-circle {
  cursor: pointer;
}

@media (max-width: 1000px) {
  #benchmark-chart {
    width: var(--benchmark-chart-width-base);    
    height: calc(var(--benchmark-chart-width-base) * 0.5);
    min-width: unset;
    min-height: unset;
  }
}
