:root {
  --ranking-table-width: 85vw;
  --ranking-table-min-width: 500px;
}

#ranking-container {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-top: 3vw;
}