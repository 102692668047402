#loading-screen__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgb(26, 18, 70), rgb(136, 0, 63));
  z-index: 1001;
}

#loading-screen__container > h1 {
  font-size: 10vh;
  color: white;
}