:root {
  --wallet-font-size: 14px;
  --wallet-height-padding: 8px;

  --wallet-font-size-medium: calc(var(--wallet-font-size) * var(--medium-multiple));
  --wallet-height-padding-medium: calc(var(--wallet-height-padding) * var(--medium-multiple));

  --wallet-font-size-large: calc(var(--wallet-font-size) * var(--large-multiple));
  --wallet-height-padding-large: calc(var(--wallet-height-padding) * var(--large-multiple));
}

#wallet__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

#wallet__account-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translate(-40px, 5px);
}