.model-explore-modal__container {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 10%;
  margin-top: 5%;
}

.model-explore-modal__container p {
  font-size: 0.9em;
}

#model-explore__buttons-container {
  width: 80%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#model-explore-button--watch,
#model-explore-button--inspect,
#model-explore-button--benchmark {
  width: 25%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}
#model-explore-button--watch > img,
#model-explore-button--inspect > img,
#model-explore-button--benchmark > img {
  height: 70%;
  width: auto;
}

#model-explore-benchmark-visual {
  width: 60%;
  height: 60%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#model-explore-benchmark-visual--bar1,
#model-explore-benchmark-visual--bar2,
#model-explore-benchmark-visual--bar3 {
  border: 2px solid white;
  border-radius: 0.15vw;
  width: 25%;
}

#model-explore-benchmark-visual--bar1 {
  height: 50%;
}
#model-explore-benchmark-visual--bar2 {
  height: 80%;
}
#model-explore-benchmark-visual--bar3 {
  height: 20%;
}